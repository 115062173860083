@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Mrs+Saint+Delafield&display=swap');

html, 
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: 'Lato', sans-serif;
}

video {
  object-fit: cover;
}
